import axios from "axios";
import apiRoot from "./apiRoot";

const getAuthState = async (setAuthState) => {
  setAuthState({ loading: true });
  // Get user and all publishing accounts
  const authRequest = await axios.post(
    apiRoot,
    {
      query: `
      query getAuthData {
        me {
          id
          name
          email
          labelArtists {
            id
            name
            owner
            label {
              id
              name
              thumbnail_url
            }
            statements {
              id
              period
              start_date
              end_date
              link
            }
          }
        }
      }`,
    },
    { withCredentials: true }
  );
  if (authRequest.data.data && authRequest.data.data.me) {
    const me = authRequest.data.data.me;
    setAuthState({
      loading: false,
      authenticated: true,
      user: me,
    });
  } else {
    window.location = "https://my.music.ceo/login/statements";
  }
};

export default getAuthState;
