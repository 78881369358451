import React from "react";
import { useGlobal } from "reactn";
import styled from "styled-components";
import { Link } from "react-router-dom";
import axios from "axios";
import apiRoot from "../apiRoot";

const Container = styled.div`
  height: 80px;
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 11;
  margin-top: 20px;
  @media print {
    display: none;
  }
  @media screen and (max-width: 800px) {
    height: auto;
    position: static;
  }
`;

const Inner = styled.div`
  max-width: 1040px;
  padding: 0 60px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
  @media screen and (max-width: 600px) {
    padding: 0 20px;
  }
`;

const Logo = styled.svg`
  margin-left: -20px;
  @media screen and (max-width: 1120px) {
    margin-left: 0;
  }
`;

const RightSide = styled.div``;

const Links = styled.div`
  font-size: 12px;
  color: rgba(0, 0, 0, 0.7);
  margin-top: 5px;
  a {
    color: inherit;
  }
`;

const Logout = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

const LogoContainer = styled(Link)`
  font-weight: 700;
  font-size: 24px;
  display: flex;
  align-items: center;
  color: black;
  cursor: pointer;
  text-decoration: none;
`;

const Header = () => {
  let [authState] = useGlobal("authState");
  let [hideHeader] = useGlobal("hideHeader");

  const logout = async () => {
    await axios.post(
      apiRoot,
      {
        query: `
        mutation logout {
          logout
        }`,
      },
      { withCredentials: true }
    );
    window.location = "https://my.music.ceo/login/statements";
  };

  if (hideHeader) return <div />;
  return (
    <Container>
      <Inner>
        <LogoContainer to="/">
          <Logo
            width="90"
            height="90"
            viewBox="0 0 140 140"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M48.2626 35.9688C48.2623 34.5441 49.4156 33.2079 50.8251 33H54.2621V56.5626V90.9688C54.2969 92.6453 53.731 94.8298 52.9814 96.7813C51.5123 99.7522 48.9754 102.386 45.6376 104.313C42.2998 106.24 38.7262 107.151 35.4188 106.938C32.1116 106.724 28.8502 105.248 27.1376 102.281C25.4251 99.3151 25.7937 95.7835 27.2626 92.8127C28.7315 89.8418 31.2998 87.1772 34.6376 85.2501C37.9754 83.3231 41.5178 82.4431 44.8251 82.6564C45.9997 82.7322 47.1669 82.9756 48.2626 83.3751V35.9688Z"
              fill="black"
            />
            <path
              d="M96.5 106.5V62.6174L80.3 89.7974H72.38C72.38 89.7974 55.73 47.8974 53.5 47.7074C51.27 47.5174 52.3995 68.155 53 71.7074V96.0074V33.0074C53 33.0074 48.44 33.0074 54.47 33.0074C60.5 33.0074 76.61 69.3674 76.61 69.3674L98.21 33.0074H112.88L112.88 106.5H96.5Z"
              fill="black"
            />
          </Logo>
          Statements
        </LogoContainer>
        <RightSide>
          {authState && authState.user && (
            <div>
              <strong>{authState.user.name}</strong> ({authState.user.email})
              <br />
              <Links>
                <a href="https://my.music.ceo/settings">Settings</a> |{" "}
                <a href="https://payments.music.ceo">Payment Portal</a> |{" "}
                <Logout onClick={logout}>Logout</Logout>
              </Links>
            </div>
          )}
        </RightSide>
      </Inner>
    </Container>
  );
};

export default Header;
