import React from "react";
import styled from "styled-components";

const BalanceSummary = styled.div`
  border: 2px solid rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  h3 {
    margin-top: 0;
  }
  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
`;

const Title = styled.h3`
  margin-top: 50px;
`;

const BalanceInfo = styled.div`
  display: flex;
  justify-content: space-between;
  strong {
    margin-left: 50px;
  }
`;

const TrackBlock = styled.div`
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;
  padding: 15px;
  small {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.6);
    margin-left: 5px;
  }
`;

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const accumulateBy = (rows, field) => {
  let accum = {};
  for (const row of rows) {
    if (accum[row[field]]) {
      accum[row[field]] += row.amount;
    } else {
      accum[row[field]] = row.amount;
    }
  }
  return Object.keys(accum)
    .map((i) => ({
      field: i,
      total: accum[i].toFixed(2),
    }))
    .sort((a, b) => Number(b.total) - Number(a.total));
};

const ArtistStatement = (props) => {
  const advancesThisPeriod = props.statement.tracks
    .filter(
      (i) =>
        new Date(i.release_date) > new Date(props.start_date) &&
        new Date(i.release_date) < new Date(props.end_date)
    )
    .map((i) =>
      i.advances
        ? i.advances.map((x) => ({ ...x, track: i, splits: i.splits }))
        : []
    )
    .flat(1);
  const totalAdvance = advancesThisPeriod.reduce(
    (a, i) =>
      a +
      i.amount *
        0.5 *
        i.splits.filter((i) => i.artist_id === props.artist_id)[0].share,
    0
  );
  return (
    <div>
      <BalanceSummary>
        <div>
          <Title>Balance Summary</Title>
        </div>
        <div>
          <BalanceInfo>
            <span>Opening Balance</span>
            <strong>{formatter.format(props.statement.start_balance)}</strong>
          </BalanceInfo>
          <BalanceInfo>
            <span>Advances/Deductions</span>
            <strong>-${totalAdvance.toFixed(2)}</strong>
          </BalanceInfo>
          <BalanceInfo>
            <span>Royalties Earned</span>
            <strong>
              {formatter.format(
                props.statement.royalty_rows.reduce((a, i) => a + i.amount, 0)
              )}
            </strong>
          </BalanceInfo>
          <BalanceInfo>
            <span>Closing Balance</span>
            <strong>{formatter.format(props.statement.end_balance)}</strong>
          </BalanceInfo>
        </div>
      </BalanceSummary>
      {advancesThisPeriod.length > 0 && <Title>Advances</Title>}
      {advancesThisPeriod.map((i) => (
        <TrackBlock key={i.track.id}>
          <span>
            {i.rename || i.track.title}{" "}
            <small>{i.track.recording_artists.join(", ")}</small>
          </span>
          <strong>
            $
            {(
              i.amount *
              0.5 *
              i.splits.filter((i) => i.artist_id === props.artist_id)[0].share
            ).toFixed(2)}
          </strong>
        </TrackBlock>
      ))}
      <Title>Tracks</Title>
      {accumulateBy(props.statement.royalty_rows, "track").map((i) => {
        const t = props.statement.tracks.filter(
          (x) => x.id === Number(i.field)
        )[0];
        return (
          <TrackBlock key={i.field}>
            <span>
              {t.title} <small>{t.recording_artists.join(", ")}</small>
            </span>
            <strong>${i.total}</strong>
          </TrackBlock>
        );
      })}
      <Title>Sources</Title>
      {accumulateBy(props.statement.royalty_rows, "source").map((i) => {
        return (
          <TrackBlock key={i.field}>
            <span>{i.field}</span>
            <strong>${i.total}</strong>
          </TrackBlock>
        );
      })}
      <Title>Months</Title>
      {accumulateBy(props.statement.royalty_rows, "period").map((i) => {
        return (
          <TrackBlock key={i.field}>
            <span>{i.field}</span>
            <strong>${i.total}</strong>
          </TrackBlock>
        );
      })}
      <Title>Territories</Title>
      {accumulateBy(props.statement.royalty_rows, "territory").map((i) => {
        return (
          <TrackBlock key={i.field}>
            <span>{i.field === "null" ? "(unknown)" : i.field}</span>
            <strong>${i.total}</strong>
          </TrackBlock>
        );
      })}
    </div>
  );
};

export default ArtistStatement;
