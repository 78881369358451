import React, { useState } from "react";
import { useEffect, useGlobal } from "reactn";
import axios from "axios";
import styled from "styled-components";

import VisualStatement from "./VisualStatement";
import ArtistStatement from "./ArtistStatement";
import OwnerStatement from "./OwnerStatement";

const LabelHeading = styled.div`
  display: flex;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 20px;
  justify-content: space-between;
`;

const LabelImage = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 100px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
  margin-right: 20px;
  @media screen and (max-width: 600px) {
    width: 100px;
    height: 100px;
  }
`;

const LabelText = styled.div`
  display: flex;
  height: 150px;
  flex-direction: column;
  justify-content: space-between;
  div {
    text-align: right;
  }
`;

const LabelName = styled.div`
  font-weight: bold;
  font-size: 24px;
  margin-bottom: -3px;
  @media screen and (max-width: 600px) {
    font-size: 20px;
  }
`;

const Title = styled.div`
  font-size: 18px;
  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
`;

const LabelSubtitle = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  @media screen and (max-width: 600px) {
    font-size: 11px;
  }
`;

const Info = styled.div`
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 10px 15px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.8);
  margin: 30px 0;
  a {
    color: inherit;
  }
  @media screen and (max-width: 600px) {
    font-size: 10px;
  }
`;

const Statement = (props) => {
  let [authState] = useGlobal("authState");
  let [data, setData] = useState(null);
  let [statement, setStatement] = useState(null);

  useEffect(() => {
    if (!authState || !authState.user) return;
    let statement = authState.user.labelArtists
      .map((i) =>
        i.statements.map((x) => ({
          ...x,
          label: i.label,
          artist: { id: i.id, name: i.name, owner: i.owner },
        }))
      )
      .flat(1)
      .filter((s) => s.id === Number(props.match.params.id))[0];
    if (!statement) return setStatement(false);
    setStatement(statement);
    async function getStatement() {
      setData((await axios.get(statement.link)).data);
    }
    getStatement();
  }, [setData, authState, props.match.params.id]);

  if (statement === null || data === null)
    return (
      <LabelHeading>
        <LabelSubtitle>Loading...</LabelSubtitle>
      </LabelHeading>
    );
  if (statement === false)
    return (
      <LabelHeading>
        <LabelSubtitle>Statement not found!</LabelSubtitle>
      </LabelHeading>
    );
  const balance = Number(
    data.type === "visual_artist" ? data.payable : data.end_balance
  );
  return (
    <div style={{ width: "100%" }}>
      <LabelHeading>
        <LabelImage src={statement.label.thumbnail_url} />
        <LabelText>
          <div>
            <LabelName>{statement.label.name}</LabelName>
            <Title>
              Royalty Statement for{" "}
              {statement.artist.owner ? "Label Owner" : statement.artist.name}
            </Title>
            <LabelSubtitle>
              {statement.period} ({statement.start_date} to {statement.end_date}
              )
            </LabelSubtitle>
          </div>
          {(data.type === "artist" || data.type === "visual_artist") && (
            <Title>
              Closing Balance:{" "}
              <strong>
                {balance < 0
                  ? `-$${(balance * -1).toFixed(2)}`
                  : `$${balance.toFixed(2)}`}
              </strong>
            </Title>
          )}
          {data.type === "owner" && (
            <Title>
              Total Earnings:{" "}
              <strong>
                $
                {data.rows.reduce((p, c) => p + Number(c.amount), 0).toFixed(2)}
              </strong>
              <br />
              Total Royalty:{" "}
              <strong>
                $
                {data.balances
                  .filter((i) => Number(i.balance) > 0)
                  .reduce((p, c) => p + Number(c.balance), 0)
                  .toFixed(2)}
              </strong>
            </Title>
          )}
        </LabelText>
      </LabelHeading>
      {(data.type === "visual_artist" ? data.payable : data.end_balance) >
        0 && (
        <Info>
          Your closing balance for this statement was positive, so we've
          transferred your balance into{" "}
          <a href="https://payments.music.ceo">Music Inc. Payments</a>, where
          you can request a payout at any point where your total balance is
          above $10 USD. Note that you won't see a starting balance on your next
          statement, as only negative balances on statements are carried forward
          - your money is safe in Music Inc. Payments for whenever you're ready
          to cash out.
        </Info>
      )}
      {data.type === "visual_artist" && <VisualStatement statement={data} />}
      {data.type === "artist" && (
        <ArtistStatement
          statement={data}
          start_date={statement.start_date}
          end_date={statement.end_date}
          artist_id={data.id}
        />
      )}
      {data.type === "owner" && <OwnerStatement statement={data} />}
    </div>
  );
};

export default Statement;
