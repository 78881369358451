import React from "react";
import styled from "styled-components";

const Title = styled.h3``;

const TrackContainer = styled.div`
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  width: 100%;
  margin-bottom: 25px;
`;

const TrackBlock = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px;
  small {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.6);
    margin-left: 5px;
  }
`;

const TrackBlockBottom = styled.div`
  background: rgba(0, 0, 0, 0.05);
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
  padding: 15px;
  font-weight: 500;
`;

const SmallTitle = styled.h5`
  margin-top: 0;
  margin-bottom: 5px;
  letter-spacing: 0.3px;
  color: rgba(0, 0, 0, 0.5);
`;

const Track = (props) => {
  return (
    <TrackContainer>
      <TrackBlock>
        <span>
          {props.track.title}{" "}
          <small>{props.track.recording_artists.join(", ")}</small>
        </span>
        <strong>
          $
          {(
            Number(props.payment.immediate_recoupable_payable) +
            Number(props.payment.post_recoupment_split)
          ).toFixed(2)}
        </strong>
      </TrackBlock>
      <TrackBlockBottom>
        <SmallTitle>PAYMENT/RECOUPMENT DETAILS</SmallTitle>
        {Number(props.payment.total_advance) > 0 && (
          <div>
            ${props.payment.total_recouped} of ${props.payment.total_advance}{" "}
            recouped
          </div>
        )}
        {Number(props.payment.immediate_recoupable_payable) > 0 && (
          <div>
            ${props.payment.immediate_recoupable_payable} of immediate
            recoupable payments included
          </div>
        )}
        {Number(props.payment.post_recoupment_split) > 0 && (
          <div>
            ${props.payment.post_recoupment_split} of your (post-recoupment)
            earnings share included
          </div>
        )}
      </TrackBlockBottom>
    </TrackContainer>
  );
};

const VisualStatement = (props) => {
  return (
    <div>
      <Title>Tracks</Title>
      {props.statement.payments
        .filter(
          (i) =>
            Number(i.immediate_recoupable_payable) +
              Number(i.post_recoupment_split) >
              0 || Number(i.total_recouped) > 0
        )
        .map((i) => (
          <Track
            key={i.track}
            payment={i}
            track={props.statement.tracks.filter((x) => i.track === x.id)[0]}
          />
        ))}
    </div>
  );
};

export default VisualStatement;
