import React, { useEffect } from "react";
import { useGlobal } from "reactn";
import { Route as BaseRoute, Switch, withRouter } from "react-router-dom";
import styled from "styled-components";

import Header from "./components/Header";
import Home from "./pages/Home";
import Statement from "./pages/Statement";
import getAuthState from "./getAuthState";
import NotFound from "./pages/NotFound";

class ScrollToTopRoute extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { component: Component, ...rest } = this.props;

    return <BaseRoute {...rest} render={(props) => <Component {...props} />} />;
  }
}

const Route = withRouter(ScrollToTopRoute);

const AppFlex = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1040px;
  padding: 0 60px;
  background: white;
  margin: 0 auto;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
  @media print {
    box-shadow: none;
  }
  @media screen and (max-width: 600px) {
    padding: 0 20px;
  }
`;

const MainFlex = styled.main`
  padding-top: 80px;
  flex: 1;
  display: flex;
  min-height: calc(100vh - 80px);
  @media print {
    padding-top: 0;
  }
  @media screen and (max-width: 800px) {
    padding-top: 0;
  }
`;

function App() {
  let [, setAuthState] = useGlobal("authState");
  useEffect(() => getAuthState(setAuthState), [setAuthState]);

  return (
    <AppFlex>
      <Header />
      <MainFlex>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/:id" component={Statement} />
          <Route component={NotFound} />
        </Switch>
      </MainFlex>
    </AppFlex>
  );
}

export default App;
