import React from "react";
import { useGlobal } from "reactn";
import { Link } from "react-router-dom";
import styled from "styled-components";

const LabelHeading = styled.div`
  display: flex;
  margin-top: 50px;
  margin-bottom: 20px;
  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
`;

const LabelImage = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 100px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
  margin-right: 20px;
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

const LabelHeader = styled.h3`
  margin-bottom: 5px;
  color: black;
  display: inline-block;
  font-size: 26px;
  @media screen and (max-width: 800px) {
    font-size: 20px;
  }
`;

const LabelSubtitle = styled.div`
  font-weight: bold;
  color: rgba(0, 0, 0, 0.5);
  @media screen and (max-width: 800px) {
    font-size: 14px;
  }
`;

const StatementLink = styled(Link)`
  font-weight: bold;
  font-size: 20px;
  color: black;
  display: flex;
  align-items: center;
  text-decoration: none;
  margin: 15px 0;
  svg {
    margin-left: 10px;
    transition: margin-left 0.2s;
  }
  :hover {
    svg {
      margin-left: 15px;
    }
  }
`;

const Home = () => {
  const [authState] = useGlobal("authState");

  return (
    <div style={{ paddingBottom: "40px" }}>
      {authState?.user?.labelArtists.map((i) => (
        <div key={i.id}>
          <LabelHeading>
            <LabelImage src={i.label.thumbnail_url} />
            <div>
              <LabelHeader>{i.label.name}</LabelHeader>
              <LabelSubtitle>
                Royalty Statements for {i.owner ? "Label Owner" : i.name}
              </LabelSubtitle>
            </div>
          </LabelHeading>
          {i.statements.map((x) => (
            <StatementLink key={x.id} to={"/" + x.id}>
              {x.period}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                viewBox="0 0 100 100"
                width="19"
                height="19"
              >
                <g>
                  <polygon points="59.4,11.9 49.8,21.6 71.4,43.2 2.5,43.2 2.5,56.8 71.4,56.8 49.8,78.4 59.4,88.1 97.5,50" />
                </g>
              </svg>
            </StatementLink>
          ))}
          {i.statements.length === 0 && (
            <LabelSubtitle>
              This label hasn't issued any statements to you yet.
            </LabelSubtitle>
          )}
        </div>
      ))}
      {authState?.user?.labelArtists.length === 0 && (
        <LabelSubtitle>
          <br />
          <br />
          Your account isn't connected to any labels.
        </LabelSubtitle>
      )}
    </div>
  );
};

export default Home;
