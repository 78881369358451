import React from "react";
import styled from "styled-components";

const Title = styled.h3`
  margin-top: 50px;
`;

const TrackBlock = styled.div`
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;
  padding: 15px;
  small {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.6);
    margin-left: 5px;
  }
`;

const accumulateBy = (rows, field) => {
  let accum = {};
  for (const row of rows) {
    if (accum[row[field]]) {
      accum[row[field]] += Number(row.amount);
    } else {
      accum[row[field]] = Number(row.amount);
    }
  }
  return Object.keys(accum)
    .map((i) => ({
      field: i,
      total: accum[i].toFixed(2),
    }))
    .sort((a, b) => Number(b.total) - Number(a.total));
};

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const OwnerStatement = (props) => {
  return (
    <div>
      <Title>Artists</Title>
      {props.statement.balances
        .filter(
          (i) =>
            Number(
              props.statement.earnings.filter((x) => x.artist === i.artist)[0]
                .amount
            ) > 0 ||
            Number(i.amount) > 0 ||
            Number(i.balance) !== 0
        )
        .map((i) => (
          <TrackBlock key={i.artist}>
            <span>
              {props.statement.artists.filter((x) => x.id === i.artist)[0].name}
            </span>
            <span style={{ textAlign: "right" }}>
              <strong>
                $
                {
                  props.statement.earnings.filter(
                    (x) => x.artist === i.artist
                  )[0].amount
                }{" "}
                earned, {formatter.format(i.balance)} balance
              </strong>
            </span>
          </TrackBlock>
        ))}
      <Title>Tracks</Title>
      {accumulateBy(props.statement.rows, "track").map((i) => {
        const t = props.statement.tracks.filter(
          (x) => x.id === Number(i.field)
        )[0];
        return (
          <TrackBlock key={i.field}>
            <span>
              {t.title} <small>{t.recording_artists.join(", ")}</small>
            </span>
            <strong>${i.total}</strong>
          </TrackBlock>
        );
      })}
      <Title>Sources</Title>
      {accumulateBy(props.statement.rows, "source").map((i) => {
        return (
          <TrackBlock key={i.field}>
            <span>{i.field}</span>
            <strong>${i.total}</strong>
          </TrackBlock>
        );
      })}
      <Title>Months</Title>
      {accumulateBy(props.statement.rows, "period").map((i) => {
        return (
          <TrackBlock key={i.field}>
            <span>{i.field}</span>
            <strong>${i.total}</strong>
          </TrackBlock>
        );
      })}
      <Title>Territories</Title>
      {accumulateBy(props.statement.rows, "territory").map((i) => {
        return (
          <TrackBlock key={i.field}>
            <span>{i.field === "null" ? "(unknown)" : i.field}</span>
            <strong>${i.total}</strong>
          </TrackBlock>
        );
      })}
    </div>
  );
};

export default OwnerStatement;
